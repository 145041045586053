import React, { useState } from 'react';
import './App.css';

function App() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="App-header">
      <div className="header-container">
        <p className="logo">
          Tevreden Impact Met Overzicht
        </p>
        <nav className="navbar">
          <ul>
            <li>Item 1</li>
            <li>Item 2</li>
            <li>Item 3</li>
            <li onClick={toggleDropdown} className="dropdown-toggle">
              Dropdown
              {isDropdownOpen && (
                <ul className="dropdown-menu">
                  <li>Option 1</li>
                  <li>Option 2</li>
                  <li>Option 3</li>
                </ul>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default App;
