// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-header {
  background-color: #282c34;
  padding: 1rem;
}
.App-header .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.App-header .header-container .logo {
  color: white;
  font-size: 1.5rem;
  margin-left: 1rem;
}
.App-header .header-container .navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}
.App-header .header-container .navbar ul li {
  color: white;
  cursor: pointer;
  position: relative;
}
.App-header .header-container .navbar ul li.dropdown-toggle {
  position: relative;
}
.App-header .header-container .navbar ul li.dropdown-toggle .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #444;
  list-style: none;
  padding: 10px;
  margin: 0;
  display: block;
  min-width: 100px;
}
.App-header .header-container .navbar ul li.dropdown-toggle .dropdown-menu li {
  padding: 5px;
  color: white;
  cursor: pointer;
}
.App-header .header-container .navbar ul li.dropdown-toggle .dropdown-menu li:hover {
  background-color: #555;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;AACf;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,aAAa;EACb,SAAS;AACX;AACA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,sBAAsB;EACtB,gBAAgB;EAChB,aAAa;EACb,SAAS;EACT,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;AACA;EACE,sBAAsB;AACxB","sourcesContent":[".App-header {\n  background-color: #282c34;\n  padding: 1rem;\n}\n.App-header .header-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.App-header .header-container .logo {\n  color: white;\n  font-size: 1.5rem;\n  margin-left: 1rem;\n}\n.App-header .header-container .navbar ul {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  display: flex;\n  gap: 20px;\n}\n.App-header .header-container .navbar ul li {\n  color: white;\n  cursor: pointer;\n  position: relative;\n}\n.App-header .header-container .navbar ul li.dropdown-toggle {\n  position: relative;\n}\n.App-header .header-container .navbar ul li.dropdown-toggle .dropdown-menu {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  background-color: #444;\n  list-style: none;\n  padding: 10px;\n  margin: 0;\n  display: block;\n  min-width: 100px;\n}\n.App-header .header-container .navbar ul li.dropdown-toggle .dropdown-menu li {\n  padding: 5px;\n  color: white;\n  cursor: pointer;\n}\n.App-header .header-container .navbar ul li.dropdown-toggle .dropdown-menu li:hover {\n  background-color: #555;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
